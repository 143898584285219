<template></template>

<script>
import { getLead } from '@/services/lead.service'
export default {
  name: 'LeadCheckout',
  created() {
    getLead(this.$route.query.statistic_id).then((res) => {
      const checkout = this.$route.query.checkout
      const token = localStorage.getItem('t')
      if (res.status == 208) {
        if (token) {
          this.$router.push(
            `/subscription/${this.$route.params.checkoutId}?${
              checkout ? 'checkout=' + checkout : ''
            }`
          )
        } else
          this.$router.push({
            name: 'Login',
            query: {
              statistic_id: this.$route.query.statistic_id,
              redirect: `/subscription/${this.$route.params.checkoutId}?${
                checkout ? 'checkout=' + checkout : ''
              }`,
              u: 'lead',
            },
          })
      } else {
        this.$router.push({
          name: 'CreateAccount',
          query: {
            statistic_id: this.$route.query.statistic_id,
            redirect: `/subscription/${this.$route.params.checkoutId}?${
              checkout ? 'checkout=' + checkout : ''
            }`,
            u: 'lead',
          },
        })
      }
    })
  },
}
</script>

<style></style>
